<template>
    <v-container fluid>
        <v-form @submit.prevent="getCategoryProducts('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'category_product.create'}" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="1" sm="1" style="max-width: 50px">
                                <v-btn :to="{name: 'dashboard'}"  class="back-btn" link large>
                                    <v-icon>mdi-less-than</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-subheader class="headline">{{ $t('search_category_product') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'category_product.create'}" dark color="indigo">{{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="category_product" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="category_product" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-folder-outline theme--light"
                                                  :label="$t('category_product')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="category_shop"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="category_shop" :items="itemCategoryShop"
                                              :error-messages="errors" :disabled="loading" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-folder-text-outline" :label="$t('category_shop')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text class="px-0">
                <v-data-table :headers="headers" :items="category_productItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalCategoryProducts" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editCategoryProduct(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="item.deleted" @click="deleteCategoryProduct(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import {mask} from 'vue-the-mask'
import debounce from "lodash/debounce";

export default {
    name: "CategoryProducts",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            sortBy: "name",
            sortDir: true,
            loading: false,
            loadingCategoryShop: false,
            category_shop: null,
            itemCategoryShop: [],
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalCategoryProducts: 0,
            category_product: null,
            category_productItems: [],
            active: null,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            headers: [
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    text: this.$t('category_shop'),
                    align: "left",
                    sortable: true,
                    value: "category_shop"
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    sortable: true,
                    value: "active",
                    width: 105,
                },
                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ],
            first_download: 1,
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),

    },
    async mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        await this.getCategoriesShop()
        await this.getCategoryProducts('search')
    },
    watch: {
        options: {
            handler() {
                if(this.first_download === 0){
                    this.getCategoryProducts('search')
                }
                this.first_download = 0;

            },
            deep: false
        },
    },
    methods: {
        editCategoryProduct(item) {
            this.$router.push({
                name: 'category_product.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getCategoryProducts(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.category_product) {
                params.category_product = this.category_product
            }
            if (this.category_shop) {
                if (this.category_shop.id) {
                    params.category_shop =  this.category_shop.id
                } else {
                    params.category_shop =  this.category_shop
                }
            }
            params.active = this.active
            await this.$http
                .get("partner/category", {
                    params: params,
                })
                .then(res => {
                    this.category_productItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalCategoryProducts = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.category_productItems = []
                    this.totalCategoryProducts = 0
                    this.$toastr.error(this.$t('failed_to_get_list_category_products'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteCategoryProduct(item) {
            if (confirm(this.$t('delete_category_product'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`partner/category/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('category_product_has_been_deleted'))
                        this.getCategoryProducts()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('category_product_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getCategoriesShop() {
            this.loadingCategoryShop = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`partner/category_shop/`, {params: params})
                .then(res => {
                    this.itemCategoryShop = res.body.data
                    this.category_shop = parseInt(this.$route.params.category_shop) ?? null
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_category_shops'));
                    this.itemCategoryShop = []

                })
                .finally(end => {
                    this.loadingCategoryShop = false
                });
        },
    }
}
</script>
